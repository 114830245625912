import { navigate } from '@reach/router'
import { parse, stringify } from 'qs'

const updateSearchFilter = event => {
  const urlParams = parse(location.search.replace('?', ''))
  const newParams = stringify({ ...urlParams, s: event.target.value })
  const appendString = `?${newParams}`
  navigate(appendString, { replace: true })
}

const updateSearchFilterDropdown = ({ value, valType }) => {
  const valueAffectingPaging = value ? encodeURIComponent(value) : null
  const urlParams = parse(location.search.replace('?', ''))
  delete urlParams[valType]

  if (valueAffectingPaging) delete urlParams['page']
  if (valueAffectingPaging) urlParams[valType] = valueAffectingPaging

  const appendString = `?${stringify(urlParams)}`
  navigate(appendString, { replace: true })
}

const updateParamFilter = ({ value, valType }) => {
  const valueAffectingPaging = value ? encodeURIComponent(value) : null
  const urlParams = parse(location.search.replace('?', ''))
  delete urlParams[valType]

  if (valueAffectingPaging) delete urlParams['page']
  if (valueAffectingPaging) urlParams[valType] = valueAffectingPaging

  const appendString = `?${stringify(urlParams)}`
  navigate(appendString, { replace: true })
}

export default {
  updateSearchFilter,
  updateSearchFilterDropdown,
  updateParamFilter
}
export { updateSearchFilter, updateSearchFilterDropdown, updateParamFilter }
